// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Company const
export const COMPANY_CREATE = 'COMPANY_CREATE';
export const COMPANY_CREATE_SUCESS = 'COMPANY_CREATE_SUCESS';
export const COMPANY_CREATE_ERROR = 'COMPANY_CREATE_ERROR';
export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_UPDATE_SUCESS = 'COMPANY_UPDATE_SUCESS';
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR';
export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_WORD = 'COMPANY_WORD';
export const COMPANY_LIST_SUCESS = 'COMPANY_LIST_SUCESS';
export const COMPANY_WORD_SUCESS = 'COMPANY_WORD_SUCESS';
export const COMPANY_LIST_ERROR = 'COMPANY_LIST_ERROR';
export const COMPANY_DELETE = 'COMPANY_DELETE';
export const COMPANY_DELETE_SUCESS = 'COMPANY_DELETE_SUCESS';
export const COMPANY_DELETE_ERROR = 'COMPANY_DELETE_ERROR';
export const COMPANY_UPDATE_TUTORIAL_STEP = 'COMPANY_UPDATE_TUTORIAL_STEP';

export const ERR_CONNECTION_REFUSED = 'ERR_CONNECTION_REFUSED';

//Channel const
export const CHANNEL_CREATE = 'CHANNEL_CREATE';
export const CHANNEL_CREATE_SUCCESS = 'CHANNEL_CREATE_SUCCESS';
export const CHANNEL_CREATE_ERROR = 'CHANNEL_CREATE_ERROR';

export const CHANNEL_UPDATE = 'CHANNEL_UPDATE';
export const CHANNEL_UPDATE_SUCCESS = 'CHANNEL_UPDATE_SUCCESS';
export const CHANNEL_UPDATE_ERROR = 'CHANNEL_UPDATE_ERROR';

export const CHANNEL_GET_ALL = 'CHANNEL_GET_ALL';
export const CHANNEL_GET_ALL_SUCCESS = 'CHANNEL_GET_ALL_SUCCESS';
export const CHANNEL_GET_ALL_ERROR = 'CHANNEL_GET_ALL_ERROR';

export const CHANNEL_DELETE = 'CHANNEL_DELETE';
export const CHANNEL_DELETE_SUCCESS = 'CHANNEL_DELETE_SUCCESS';
export const CHANNEL_DELETE_ERROR = 'CHANNEL_DELETE_ERROR';

export const CHANNEL_DETAIL = 'CHANNEL_DETAIL';
export const CHANNEL_DETAIL_SHOW = 'CHANNEL_DETAIL_SHOW';
export const CHANNEL_DETAIL_HIDE = 'CHANNEL_DETAIL_HIDE';

export const CHANNEL_MODAL_VISIBLE = 'CHANNEL_MODAL_VISIBLE';
export const CHANNEL_COMPONENT_VISIBLE = 'CHANNEL_COMPONENT_VISIBLE';

//Loading const
export const LOADING_BUTTON = 'LOADING_BUTTON';
export const UNLOADING_BUTTON = 'UNLOADING_BUTTON';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const BUTTON_LOADING = 'BUTTON_LOADING';
export const COMPONENT_LOADING = 'COMPONENT_LOADING';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

//Feedback Message const
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const INFO_MESSAGE = 'INFO_MESSAGE';
export const WARNING_MESSAGE = 'WARNING_MESSAGE';

//Webhook const
export const WEBHOOK_LIST_ALL = 'WEBHOOK_LIST_ALL';
export const WEBHOOK_LIST_SUCCESS = 'WEBHOOK_LIST_SUCCESS';
export const WEBHOOK_LIST_ERROR = 'WEBHOOK_LIST_ERROR';
export const WEBHOOK_CREATE = 'WEBHOOK_CREATE';
export const WEBHOOK_CREATE_SUCCESS = 'WEBHOOK_CREATE_SUCCESS';
export const WEBHOOK_CREATE_ERROR = 'WEBHOOK_CREATE_ERROR';
export const WEBHOOK_MODAL_VISIBLE = 'WEBHOOK_MODAL_VISIBLE';
export const WEBHOOK_DELETE = 'WEBHOOK_DELETE';
export const WEBHOOK_DELETE_SUCCESS = 'WEBHOOK_DELETE_SUCCESS';
export const WEBHOOK_DELETE_ERROR = 'WEBHOOK_DELETE_ERROR';
export const WEBHOOK_UPDATE = 'WEBHOOK_UPDATE';
export const WEBHOOK_UPDATE_SUCCESS = 'WEBHOOK_UPDATE_SUCCESS';
export const WEBHOOK_UPDATE_ERROR = 'WEBHOOK_UPDATE_ERROR';
export const WEBHOOK_COMPONENT_VISIBLE = 'WEBHOOK_COMPONENT_VISIBLE';

//Conversation
export const CONVERSATION_TOTAL_MESSAGES = "CONVERSATION_TOTAL_MESSAGES"
export const CONVERSATION_TOTAL_MESSAGES_SUCCESS = "CONVERSATION_TOTAL_MESSAGES_SUCCESS"
export const CONVERSATION_TOTAL_MESSAGES_ERROR = "CONVERSATION_TOTAL_MESSAGES_ERROR"
export const CONVERSATION_TOTAL_UNDERSTAND = "CONVERSATION_TOTAL_UNDERSTAND"
export const CONVERSATION_MESSAGES = "CONVERSATION_MESSAGES"
export const CONVERSATION_MESSAGES_SUCCESS = "CONVERSATION_MESSAGES_SUCCESS"
export const CONVERSATION_TOTAL_UNDERSTAND_SUCCESS = "CONVERSATION_TOTAL_UNDERSTAND_SUCCESS"
export const CONVERSATION_TOTAL_UNDERSTAND_ERROR = "CONVERSATION_TOTAL_UNDERSTAND_ERROR"
export const CONVERSATION_TOP_INTENTS = "CONVERSATION_TOP_INTENTS"
export const CONVERSATION_TOP_INTENTS_SUCCESS = "CONVERSATION_TOTAL_UNDERSTAND_ERROR"
export const CONVERSATION_TOP_INTENTS_ERROR = "CONVERSATION_TOP_INTENTS_ERROR"
export const CONVERSATION_TOP_ENTITIES = "CONVERSATION_TOP_ENTITIES"
export const CONVERSATION_TOP_ENTITIES_SUCCESS = "CONVERSATION_TOP_ENTITIES_SUCCESS"
export const CONVERSATION_TOP_ENTITIES_ERROR = "CONVERSATION_TOP_ENTITIES_ERROR"
export const CONVERSATION_IN_PROGRESS = "CONVERSATION_IN_PROGRESS"
export const CONVERSATION_IN_PROGRESS_SUCCESS = "CONVERSATION_IN_PROGRESS_SUCCESS"
export const CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST = "CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST"
export const CONVERSATION_IN_PROGRESS_UPDATE = "CONVERSATION_IN_PROGRESS_UPDATE"
export const RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_MESSAGES = "RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_MESSAGES"
export const RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_UNDERSTAND = "RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_UNDERSTAND"
export const RECEIVED_NEW_ENTITIES = "RECEIVED_NEW_ENTITIES"
export const RECEIVED_NEW_INTENTS = "RECEIVED_NEW_INTENTS"
export const SET_LOADING_CONVERSATION_ITEM = "SET_LOADING_CONVERSATION_ITEM"

//Integration
export const INTEGRATION_MODAL_VISIBLE = "INTEGRATION_MODAL_VISIBLE"

//Filter const
export const SET_FILTER_DASHBOARD = 'SET_FILTER_DASHBOARD';
export const SET_FILTER_EVENTS = 'SET_FILTER_EVENTS';
export const SET_FILTER_UNDERSTANDING = 'SET_FILTER_UNDERSTANDING';
export const SET_FILTER_BOT = 'SET_FILTER_BOT';
export const SET_FILTER_CHANNEL = 'SET_FILTER_CHANNEL';
export const SET_FILTER_ITEM = 'SET_FILTER_ITEM';

export const PAGE_BOTS = "BOTS"
export const PAGE_CHANNELS = "CHANNELS"
export const PAGE_ACTIONS = "ACTIONS"
export const PAGE_INTERVENTION_EVENTS = "INTERVENTION_EVENTS"
export const PAGE_FILES = "FILES"
export const PAGE_DASHBOARD = "DASHBOARD"
export const PAGE_LOGS = "LOGS"
export const PAGE_UNDERSTANDING = "UNDERSTANDING"
export const PAGE_CHAT = "CHAT"
export const PAGE_INTERVENTION = "INTERVENTION"
export const PAGE_COMPANIES = "COMPANIES"
export const PAGE_CONTACT = "CONTACT"
export const PAGE_USERS = "USERS"
export const PAGE_ROLES = "ROLES"
export const PAGE_PERMISSION = "PERMISSION"
export const PAGE_TEMPLATE = "TEMPLATE"
export const PAGE_CALENDARS = "CALENDARS"
export const PAGE_INACTIVITY = "INACTIVITY_CONFIG"


export const PAGE_AGENTS = "AGENTS"
//Intervention Events const
export const INTERVENTION_EVENTS_LIST_ALL = 'INTERVENTION_EVENTS_LIST_ALL';
export const INTERVENTION_EVENTS_LIST_SUCCESS = 'INTERVENTION_EVENTS_LIST_SUCCESS';
export const INTERVENTION_EVENTS_LIST_ERROR = 'INTERVENTION_EVENTS_LIST_ERROR';
export const INTERVENTION_EVENTS_CREATE = 'INTERVENTION_EVENTS_CREATE';
export const INTERVENTION_EVENTS_CREATE_SUCCESS = 'INTERVENTION_EVENTS_CREATE_SUCCESS';
export const INTERVENTION_EVENTS_CREATE_ERROR = 'INTERVENTION_EVENTS_CREATE_ERROR';
export const INTERVENTION_EVENTS_MODAL_VISIBLE = 'INTERVENTION_EVENTS_MODAL_VISIBLE';
export const INTERVENTION_EVENTS_DELETE = 'INTERVENTION_EVENTS_DELETE';
export const INTERVENTION_EVENTS_DELETE_SUCCESS = 'INTERVENTION_EVENTS_DELETE_SUCCESS';
export const INTERVENTION_EVENTS_DELETE_ERROR = 'INTERVENTION_EVENTS_DELETE_ERROR';
export const INTERVENTION_EVENTS_UPDATE = 'INTERVENTION_EVENTS_UPDATE';
export const INTERVENTION_EVENTS_UPDATE_SUCCESS = 'INTERVENTION_EVENTS_UPDATE_SUCCESS';
export const INTERVENTION_EVENTS_UPDATE_ERROR = 'INTERVENTION_EVENTS_UPDATE_ERROR';
export const INTERVENTION_EVENTS_COMPONENT_VISIBLE = 'INTERVENTION_EVENTS_COMPONENT_VISIBLE';
