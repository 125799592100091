import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";

import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";

import {
  PAGE_ACTIONS,
  PAGE_BOTS,
  PAGE_CHANNELS,
  PAGE_DASHBOARD,
  PAGE_FILES,
  PAGE_USERS,
  PAGE_COMPANIES,
  PAGE_ROLES,
  PAGE_CALENDARS,
  PAGE_INTERVENTION_EVENTS,
  PAGE_CONTACT,
  PAGE_INACTIVITY
} from "../../constants/ActionTypes";

import IntlMessages from "../../util/IntlMessages";
import { IsAuthorized } from "../../util/AuthUtil";
const MenuItemGroup = Menu.ItemGroup;
class SidebarContent extends Component {
  render() {
    const { themeType, pathname, authUser } = this.props;
    const selectedKeys = pathname.substr(1).includes("/") ? pathname.split("/")[1] : pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <p></p>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup
              key="dashboard"
              className="gx-menu-group"
              title={<IntlMessages id="sidebar.dashboard" />}
            >

            {IsAuthorized(PAGE_DASHBOARD, authUser.permissions) && (
              <Menu.Item key="analytics">
                <Link to="/analytics">
                  <Icon type="dashboard" />
                  <IntlMessages id="sidebar.analytics" />
                </Link>
              </Menu.Item>
            )}

             {IsAuthorized(PAGE_DASHBOARD, authUser.permissions) && (
                <Menu.Item key="service-monitoring">
                  <Link to="/service-monitoring">
                    <Icon type="monitor" />
                    <IntlMessages id="sidebar.dashboard.service" />
                  </Link>
                </Menu.Item>
              )}
            </MenuItemGroup>
            <MenuItemGroup
              key="customer-settings-service"
              className="gx-menu-group"
              title={<IntlMessages id="sidebar.customerServiceSettings" />}
            >
              {IsAuthorized(PAGE_COMPANIES, authUser.permissions) && (
                <Menu.Item key="companies">
                  <Link to="/companies">
                    <Icon type="bank" />
                    <IntlMessages id="sidebar.companies" />
                  </Link>
                </Menu.Item>
              )}

              {IsAuthorized(PAGE_CALENDARS, authUser.permissions) && (
                <Menu.Item key="calendars">
                  <Link to="/calendars">
                    <Icon type="calendar" />
                    <IntlMessages id="sidebar.calendar" />
                  </Link>
                </Menu.Item>
              )}
              {IsAuthorized(PAGE_CHANNELS, authUser.permissions) && (
                <Menu.Item key="channel">
                  <Link to="/channel">
                    <Icon id="channelButton" type="mobile" />
                    <IntlMessages id="sidebar.channel" />
                  </Link>
                </Menu.Item>
              )}
              {IsAuthorized(PAGE_BOTS, authUser.permissions) && (
                <Menu.Item key="bots">
                  <Link to="/bots">
                    <Icon id="botsButton" type="robot" />
                    <IntlMessages id="sidebar.bots" />
                  </Link>
                </Menu.Item>
              )}
              {IsAuthorized(PAGE_ACTIONS, authUser.permissions) && (
                <Menu.Item key="actions">
                  <Link to="/actions">
                    <Icon id="actionsButton" type="api" />
                    <IntlMessages id="sidebar.actions" />
                  </Link>
                </Menu.Item>
              )}
              
              {/* {IsAuthorized(PAGE_TEMPLATE, authUser.permissions) && ( */}
              <Menu.Item key="template" >
                <Link to="/template">
                  <Icon type="ordered-list" />
                  <IntlMessages id="sidebar.template" />
                </Link>
              </Menu.Item>
              {/* )} */}
              {IsAuthorized(PAGE_FILES, authUser.permissions) && (
                <Menu.Item key="files">
                  <Link to="/files">
                    <Icon type="cloud-upload" />
                    <IntlMessages id="sidebar.files" />
                  </Link>
                </Menu.Item>
              )}
              {IsAuthorized(PAGE_INTERVENTION_EVENTS, authUser.permissions) &&
                <Menu.Item key="intervention-events">
                  <Link to="/intervention-events"><Icon id="interventionEventsButton" type="bell" />
                    <IntlMessages id="sidebar.interventionEvents" /></Link>
                </Menu.Item>
              }
              {IsAuthorized(PAGE_INACTIVITY, authUser.permissions) && ( 
              <Menu.Item key="inactivity" >
                <Link to="/inactivity">
                  <Icon type="clock-circle" />
                  <IntlMessages id="sidebar.inactivity" />
                </Link>
              </Menu.Item>
              )}
            </MenuItemGroup>
            
            
            
            <MenuItemGroup
              key="general-settings"
              className="gx-menu-group"
              title={<IntlMessages id="sidebar.generalSettings" />}
              >
              {IsAuthorized(PAGE_ROLES, authUser.permissions) && (
                <Menu.Item key="roles">
                  <Link to="/roles">
                    <Icon type="control" />
                    <IntlMessages id="sidebar.roles" />
                  </Link>
                </Menu.Item>
              )}
             
              {/* {IsAuthorized(PAGE_PERMISSION, authUser.permissions) && ( */}
              <Menu.Item key="permission">
                <Link to="/permission">
                  <Icon type="reconciliation" />
                  <IntlMessages id="sidebar.permission" />
                </Link>
              </Menu.Item>
              {/* )} */}
              {IsAuthorized(PAGE_USERS, authUser.permissions) && (
                <Menu.Item key="users">
                  <Link to="/users">
                    <Icon type="usergroup-add" />
                    <IntlMessages id="sidebar.users" />
                  </Link>
                </Menu.Item>
              )}

              {/* {IsAuthorized(PAGE_INTERVENTION, authUser.permissions) && (
                <Menu.Item key="intervention">
                  <Link to="/intervention">
                    <Icon type="eye" />
                    <IntlMessages id="sidebar.dashboard.intervention" />
                  </Link>
                </Menu.Item>
              )} */}


              {/* {IsAuthorized(PAGE_CHAT, authUser.permissions) && (
                <Menu.Item key="chat">
                  <Link to="/chat">
                    <Icon type="message" />
                    <IntlMessages id="sidebar.dashboard.chat" />
                  </Link>
                </Menu.Item>
              )} */}

              {IsAuthorized(PAGE_CONTACT, authUser.permissions) && (
                <Menu.Item key="contacts">
                  <Link to="/contacts">
                    <Icon type="phone" />
                    <IntlMessages id="sidebar.contacts" />
                  </Link>
                </Menu.Item>
              )}



            </MenuItemGroup>
            <MenuItemGroup
            key="gestaoDeAgentes"
            className="gx-menu-group"
            title={<IntlMessages id="sidebar.managementAgents" />}
            >
              <Menu.Item key="agents" >
                <Link to="/agents">
                  <Icon type="robot" />
                  <IntlMessages id="sidebar.agents" />
                </Link>
              </Menu.Item>
            </MenuItemGroup>
          </Menu>
          <p></p> 
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser } = auth;
  return { navStyle, themeType, locale, pathname, authUser };
};
export default connect(mapStateToProps)(SidebarContent);
