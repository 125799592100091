import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import RestrictedRoute from "../components/Wrapper/RestrictedRoute";

import {
  PAGE_ACTIONS,
  PAGE_BOTS,
  PAGE_CHANNELS,
  PAGE_DASHBOARD,
  PAGE_FILES,
  PAGE_USERS,
  PAGE_COMPANIES,
  PAGE_ROLES,
  PAGE_LOGS,
  PAGE_CONTACT,
  PAGE_UNDERSTANDING,
  PAGE_CALENDARS,
  PAGE_INTERVENTION_EVENTS,
  PAGE_INACTIVITY
} from "../constants/ActionTypes";

const App = ({ match, user }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}orquestra`} resource={PAGE_DASHBOARD} component={asyncComponent(() => import('../pages/Orquestra'))} />
      <RestrictedRoute
        path={`${match.url}analytics`} resource={PAGE_DASHBOARD} user={user}
        component={asyncComponent(() => import('../pages/Dashboard'))}
      />
      <RestrictedRoute
        path={`${match.url}bots`} resource={PAGE_BOTS} user={user}
        component={asyncComponent(() => import('../pages/Bots'))}
      />
      <RestrictedRoute
        path={`${match.url}channel`} resource={PAGE_CHANNELS} user={user}
        component={asyncComponent(() => import('../pages/Channel'))}
      />
      <RestrictedRoute
        path={`${match.url}actions`} resource={PAGE_ACTIONS} user={user}
        component={asyncComponent(() => import('../pages/Webhook'))}
      />
      <RestrictedRoute
        path={`${match.url}intervention-events`} resource={PAGE_INTERVENTION_EVENTS} user={user}
        component={asyncComponent(() => import('../pages/InterventionEvents'))}
      />
      <RestrictedRoute
        path={`${match.url}logs`} resource={PAGE_LOGS} user={user}
        component={asyncComponent(() => import('../pages/ErrorLog'))}
      />
      <RestrictedRoute
        path={`${match.url}understanding`} resource={PAGE_UNDERSTANDING} user={user}
        component={asyncComponent(() => import('../pages/Understanding'))}
      />
      <RestrictedRoute
        path={`${match.url}files`} resource={PAGE_FILES} user={user}
        component={asyncComponent(() => import('../pages/Files'))}
      />
      {/* <RestrictedRoute
        path={`${match.url}intervention`} resource={PAGE_INTERVENTION} user={user}
        component={asyncComponent(() => import('../pages/Intervention'))}
      />
      <RestrictedRoute
        path={`${match.url}chat`} resource={PAGE_CHAT} user={user}
        component={asyncComponent(() => import('../pages/Chat'))}
      /> */}
      <RestrictedRoute
        path={`${match.url}users`} resource={PAGE_USERS} user={user}
        component={asyncComponent(() => import('../pages/Users'))}
      />
      <RestrictedRoute
        path={`${match.url}roles`} resource={PAGE_ROLES} user={user}
        component={asyncComponent(() => import('../pages/Roles'))}
      />
      <RestrictedRoute
        path={`${match.url}permission`} resource={PAGE_ROLES} user={user}
        component={asyncComponent(() => import('../pages/Permission'))}
      />
      <RestrictedRoute
        path={`${match.url}companies`} resource={PAGE_COMPANIES} user={user}
        component={asyncComponent(() => import('../pages/Company'))}
      />
      <RestrictedRoute
        path={`${match.url}contacts`} resource={PAGE_CONTACT} user={user}
        component={asyncComponent(() => import('../pages/Contact'))}
      />
      <RestrictedRoute
        path={`${match.url}words`} resource={PAGE_COMPANIES} user={user}
        component={asyncComponent(() => import('../pages/Words'))}
      />
      <RestrictedRoute
        path={`${match.url}calendars`} resource={PAGE_CALENDARS} user={user} exact
        component={asyncComponent(() => import('../pages/Calendar'))}
      />
      <RestrictedRoute
        path={`${match.url}calendars/:calendarId`} resource={PAGE_CALENDARS} user={user}
        component={asyncComponent(() => import('../pages/Calendar/CalendarDetails'))}
      />

      <RestrictedRoute
        path={`${match.url}template`} resource={PAGE_ROLES} user={user}
        component={asyncComponent(() => import('../pages/Template'))}
      />

      <RestrictedRoute
        path={`${match.url}service-monitoring`} resource={PAGE_DASHBOARD} user={user}
        component={asyncComponent(() => import('../pages/Dashboard/ServiceMonitoring'))}
      />
 
       <RestrictedRoute
        path={`${match.url}inactivity`} resource={PAGE_INACTIVITY} user={user}
        component={asyncComponent(() => import('../pages/Inactivity'))}
      />

      <RestrictedRoute
        exact
        path={`${match.url}agents`} resource={PAGE_BOTS} user={user}
        component={asyncComponent(() => import('../pages/Agents'))}
      />
      <RestrictedRoute 
        exact
        path={`${match.url}agents/:agentId`} resource={PAGE_BOTS} user={user}
        component={asyncComponent(() => import('../pages/Agents/AgentDetails'))}
      />

    </Switch>
  </div>
);

export default App;
