import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { getMessages } from "../../services/PlaygroundMessages";
import {
  Types,
  listSuccessPlaygroundMessages,
  setIsLoading
} from "../reducers/Agent"

import { errorMessage } from "../actions/FeedbackMessage"

export function* getPlaygroundMessagesSaga() {
    yield takeLatest(Types.LIST_PLAYGROUND_MESSAGES, function* ({ payload: filter }) {
        try {
            yield put(setIsLoading(true))
            const messages = yield call(getMessages, filter)
            yield put(listSuccessPlaygroundMessages(messages))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setIsLoading(false))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getPlaygroundMessagesSaga),
    ]);
}
